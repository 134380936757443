var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.loading)?_c('div',{staticClass:"loading"},[_c('v-dialog',{attrs:{"outlined":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"#181818","outlined":"","dark":""}},[_c('v-card-text',{staticClass:"mt-2 text-center"},[_vm._v(" Loading studies... "),_c('v-progress-linear',{staticClass:"mb-2 mt-2",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h1',{staticClass:"mb-md-8"},[_vm._v("Your studies")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mb-n8",attrs:{"append-icon":"mdi-magnify","outlined":"","color":"secondary","label":"Search","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex flex-row-reverse",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"id":"createStudyButton","outlined":""},on:{"click":function($event){return _vm.goToCreate()}}},[_vm._v(" Create new study ")])],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"card-container lightgray"},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold title"},[_vm._v("ACTIVE STUDIES")]),_c('v-data-table',{staticClass:"lightgray",attrs:{"headers":_vm.headers.filter(function (x) { return x.show; }),"items":_vm.sessions('active'),"search":_vm.search},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.getParticipantsFinished(item))?_c('v-badge',{attrs:{"color":"light-green darken-3","content":_vm.getParticipantsFinished(item),"left":_vm.mobile}},[_c('v-btn',_vm._b({attrs:{"light":""}},'v-btn',_vm.buttonSize,false),[_c('span',{staticStyle:{"text-transform":"uppercase"}},[_vm._v(_vm._s(item.state))])])],1):_c('v-btn',_vm._b({attrs:{"outlined":""}},'v-btn',_vm.buttonSize,false),[_c('span',{staticStyle:{"text-transform":"uppercase"}},[_vm._v(_vm._s(item.state))])])]}},{key:"item.created_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.created))+" ")]}},{key:"item.completes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getParticipantsStarted(item))+" "),_c('span',{staticClass:"grey--text"},[_vm._v("/")]),_vm._v(" "+_vm._s(_vm.getParticipantsFinished(item))+" ")]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('br'),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"card-container lightgray"},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold title"},[_vm._v("DRAFT STUDIES")]),_c('v-data-table',{staticClass:"lightgray",attrs:{"headers":_vm.headers.filter(function (x) { return x.show; }),"items":_vm.sessions('draft'),"search":_vm.search},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-btn',_vm._b({attrs:{"outlined":""}},'v-btn',_vm.buttonSize,false),[_c('span',{staticStyle:{"text-transform":"uppercase"}},[_vm._v(_vm._s(item.state))])])]}},{key:"item.created_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.created))+" ")]}},{key:"item.completes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getParticipantsStarted(item))+" "),_c('span',{staticClass:"grey--text"},[_vm._v("/")]),_vm._v(" "+_vm._s(_vm.getParticipantsFinished(item))+" ")]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('br'),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"card-container lightgray"},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold title"},[_vm._v("CLOSED STUDIES")]),_c('v-data-table',{staticClass:"lightgray",attrs:{"headers":_vm.headers.filter(function (x) { return x.show; }),"items":_vm.sessions('complete'),"search":_vm.search},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.status",fn:function(){return [_c('v-btn',_vm._b({attrs:{"outlined":"","disabled":""}},'v-btn',_vm.buttonSize,false),[_c('span',{staticStyle:{"text-transform":"uppercase"}},[_vm._v("CLOSED")])])]},proxy:true},{key:"item.created_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.created))+" ")]}},{key:"item.completes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getParticipantsStarted(item))+" "),_c('span',{staticClass:"grey--text"},[_vm._v("/")]),_vm._v(" "+_vm._s(_vm.getParticipantsFinished(item))+" ")]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }